import type { Pagination, PaginationQuery, Segment, SegmentQuery } from '@/types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import uniqBy from 'lodash-es/uniqBy'
import jsonStableStringify from 'json-stable-stringify'
import omit from 'lodash-es/omit'
import { DEFAULT_PAGE_SIZE } from '@/constants'
import { SWRConfiguration, SWRResponse } from '@banyudu/use-service'

export interface InfiniteResp {
  hasMore: boolean
  loadMore: () => void
}

type Infinite<T = any> = SWRResponse<T> & InfiniteResp

type InfinitePaginationHook<R = any, P = any> = (
  params?: PaginationQuery<P>,
  refreshKey?: string,
  options?: SWRConfiguration,
) => Infinite<Pagination<R>>
type InfiniteSegmentHook<R = any, P = any> = (
  params: SegmentQuery<P>,
  refreshKey?: string,
  options?: SWRConfiguration,
) => Infinite<Segment<R>>

export interface UseInfiniteOptions {
  rowKey: string
}

export default function useInfinite(customHooks: any, options?: UseInfiniteOptions): InfinitePaginationHook {
  const rowKey = options?.rowKey ?? '_id'
  const infiniteHook = ((params: any = {}, refreshKey?: string, options?: any) => {
    const [page, setPage] = useState(1)
    const canLoadMoreRef = useRef(false)

    const { limit = DEFAULT_PAGE_SIZE, page: _externalPage, ...rest } = params

    const strQuery = useMemo(() => {
      return jsonStableStringify(omit(params, ['page', 'limit']))
    }, [params])

    useEffect(() => {
      // reset when query changed
      setPage(1)
      setStaleData(null)
    }, [strQuery, refreshKey])

    const [staleData, setStaleData] = useState<Pagination<any> | null>(null)
    const pageData = customHooks({ ...rest, page, limit }, refreshKey, options)

    const loadMore = useCallback((newPage: number) => {
      if (canLoadMoreRef.current) {
        setPage((old) => newPage ?? old + 1)
        canLoadMoreRef.current = false
      }
    }, [])

    const hasMore = useMemo(() => {
      const pageSize = pageData.data?.limit ?? pageData.data?.pageSize
      if (pageData.data?.records?.length === 0 || pageData?.data?.records?.length < pageSize) return false
      const maxPage = Math.ceil(pageData.data?.total / Math.max(pageData.data?.pageSize ?? DEFAULT_PAGE_SIZE, 1))
      return page < maxPage
    }, [pageData, page])

    useEffect(() => {
      setStaleData((old) => {
        return {
          ...old,
          ...pageData.data,
          records: uniqBy([...(old?.records ?? []), ...(pageData?.data?.records ?? [])], (item) => item[rowKey]),
        }
      })
    }, [pageData.data])

    useEffect(() => {
      if (!pageData.isValidating) {
        canLoadMoreRef.current = true
      }
    }, [pageData.isValidating])

    const result = useMemo(() => {
      return {
        ...pageData,
        hasMore,
        page,
        loadMore,
        data: staleData,
      }
    }, [pageData, staleData, hasMore, loadMore, page])

    return result
  }) as any
  return infiniteHook
}

const DEFAULT_SEGMENT_START = 1

export function useInfiniteSegment(customHooks: any, options?: UseInfiniteOptions): InfiniteSegmentHook {
  const rowKey = options?.rowKey ?? 'id'
  const infiniteHook = ((params: any = {}, refreshKey?: string, options?: any) => {
    const [start, setStart] = useState<string | null>(null)
    const { limit = DEFAULT_PAGE_SIZE, ...rest } = params

    const strQuery = useMemo(() => {
      return jsonStableStringify(omit(params, ['limit', 'start']))
    }, [params])

    const [staleData, setStaleData] = useState<Segment<any> | null>(null)
    useEffect(() => {
      // reset when query changed
      setStart(null)
      setStaleData(null)
    }, [strQuery, refreshKey])

    const segmentData = customHooks({ ...rest, start: start ?? DEFAULT_SEGMENT_START, limit }, refreshKey, options)

    const loadMore = useCallback(() => {
      if (staleData?.next) {
        setStart(staleData?.next ?? null)
      }
    }, [staleData])

    const hasMore = useMemo(() => {
      return !!segmentData?.data?.next
    }, [segmentData])

    useEffect(() => {
      setStaleData((old) => {
        return {
          ...old,
          ...segmentData.data,
          records: uniqBy([...(old?.records ?? []), ...(segmentData?.data?.records ?? [])], (item) => item[rowKey]),
        }
      })
    }, [segmentData.data])

    const result = useMemo(() => {
      return {
        ...segmentData,
        hasMore,
        loadMore,
        data: staleData,
      }
    }, [segmentData, staleData, hasMore, loadMore])

    return result
  }) as any
  return infiniteHook
}
