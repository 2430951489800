import React, { forwardRef, ComponentPropsWithoutRef, useCallback } from 'react'
import { cls } from '@/utils'

export interface InputProps extends ComponentPropsWithoutRef<'input'> {}

const Input = forwardRef<HTMLInputElement, InputProps>(({ className, type, disabled, onChange, ...props }, ref) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e)
      }
    },
    [onChange],
  )

  return (
    <div
      className={cls(
        'flex gap-2 relative h-10 bg-surface w-full rounded-md border border-input focus-within:border-border-hover px-3 py-2 text-body-md ring-offset-background file:border-0 file:bg-transparent file:text-body-md file:font-medium placeholder:text-text-subdued focus-visible:outline-none focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
        disabled ? 'opacity-50 cursor-not-allowed' : '',
        className,
      )}
    >
      <input
        ref={ref}
        type={type}
        className='placeholder:text-text-subdued focus:border-0 flex-1 focus-visible:border-0 focus-visible:outline-none bg-surface max-w-full'
        disabled={disabled}
        onChange={handleChange}
        {...props}
      />
    </div>
  )
})
Input.displayName = 'Input'

export { Input }
